import React, { useState, useEffect } from "react";
import "./admin.css";
import { Link } from "react-router-dom";
import NewBlog from "./NewBlog";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [showNewBlogPopup, setShowNewBlogPopup] = useState(false);
  const [replyInputs, setReplyInputs] = useState({});
  const [openReplyIndex, setOpenReplyIndex] = useState(null);

  // Fetch blogs from API
  const fetchBlogs = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/blogs`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setBlogs(
        data.map((blog) => ({
          ...blog,
          formattedDateTime: formatDateTime(blog.dateTime), // Add formattedDateTime property
        }))
      );
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  // Format date time to DD-MM-YY Time
  const formatDateTime = (dateTimeStr) => {
    const dateTime = new Date(dateTimeStr);
    const day = dateTime
      .getDate()
      .toString()
      .padStart(2, "0");
    const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
    const year = dateTime.getFullYear();
    let hours = dateTime.getHours();
    const minutes = dateTime
      .getMinutes()
      .toString()
      .padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)
    const formattedTime = hours.toString().padStart(2, "0") + ":" + minutes;
    return `${day}-${month}-${year} ${formattedTime} ${ampm}`;
  };

  // Open modal with selected blog details
  const openModal = (blog) => {
    setSelectedBlog(blog);
    initializeReplyInputs(blog);
  };

  // Initialize reply input state for each comment
  const initializeReplyInputs = (blog) => {
    const initialInputs = {};
    blog.comments.forEach((comment) => {
      initialInputs[comment.commentId] = ""; // Use commentId as key for replyInputs
    });
    setReplyInputs(initialInputs);
  };

  // Handle input change for reply input fields
  const handleReplyInputChange = (commentId, event) => {
    const { value } = event.target;
    setReplyInputs((prevInputs) => ({
      ...prevInputs,
      [commentId]: value,
    }));
  };

  // Handle sending a reply
  const handleReplySend = async (_id, commentId) => {
    const reply = replyInputs[commentId];
    const dateTime = new Date().toISOString();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/blogs/${_id}/comments/${commentId}/replies`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ reply, dateTime }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add reply");
      }

      // Update selectedBlog with the new reply
      const updatedBlog = { ...selectedBlog };
      const commentIndex = updatedBlog.comments.findIndex(
        (comment) => comment.commentId === commentId
      );
      updatedBlog.comments[commentIndex].replies.push({ reply, dateTime });

      setSelectedBlog(updatedBlog); // Update selectedBlog state directly

      setReplyInputs((prevInputs) => ({
        ...prevInputs,
        [commentId]: "",
      }));
    } catch (error) {
      console.error("Error adding reply:", error);
    }
  };

  // Toggle reply input box visibility
  const toggleReplyBox = (commentId) => {
    setOpenReplyIndex(commentId === openReplyIndex ? null : commentId);
  };

  // Open popup for adding new blog
  const openNewBlogPopup = () => {
    setShowNewBlogPopup(true);
  };

  // Close popup for adding new blog
  const closeNewBlogPopup = () => {
    setShowNewBlogPopup(false);
  };

  return (
    <div className="">
      <div className="blog2-heading">
        <h2>Blogs</h2>
        <Link className="text-decoration-none m-2" onClick={openNewBlogPopup}>
          Add New Blog
        </Link>
      </div>
      <div className="blogs2">
        <ul style={{ padding: 0 }}>
          {blogs.map((blog) => (
            <div className="blog2-li" key={blog._id}>
              <li>
                <strong>Blog Title:</strong> {blog.blogTitle}
                <br />
                <strong>Blog Description:</strong> {blog.blogDescription}
                <br />
                <strong>Date Time:</strong> {blog.formattedDateTime}
                <br />
                <Link
                  to="#"
                  className="text-decoration-none"
                  onClick={() => openModal(blog)}
                >
                  Details
                </Link>
              </li>
            </div>
          ))}
        </ul>
      </div>

      {/* Modal for Blog Details */}
      <div
        className={`modal ${selectedBlog ? "show" : ""}`}
        id="blogModal"
        tabIndex="-1"
        aria-labelledby="blogModalLabel"
        style={{ display: selectedBlog ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <div>
                <h5 className="modal-title" id="blogModalLabel">
                  {selectedBlog && selectedBlog.blogTitle}
                </h5>
                <small>
                  <strong>
                    {" "}
                    {selectedBlog && selectedBlog.formattedDateTime}{" "}
                  </strong>
                </small>
              </div>
              <Link
                type="button"
                className="btn-close text-decoration-none"
                aria-label="Close"
                onClick={() => setSelectedBlog(null)}
              ></Link>
            </div>
            <div className="modal-body">
              <div className="discreption">
                {selectedBlog && selectedBlog.blogDescription}
              </div>
              <div className="comments-admin mt-3">
                {selectedBlog && selectedBlog.comments && (
                  <ul>
                    {selectedBlog.comments.map((comment, index) => (
                      <li key={comment.commentId}>
                        * {comment.comment}
                        <br />
                        <div>
                          <small
                            onClick={() => toggleReplyBox(comment.commentId)}
                          >
                            Reply
                          </small>
                          {openReplyIndex === comment.commentId && (
                            <div>
                              {/* Display replies */}
                              {comment.replies && comment.replies.length > 0 && (
                                <ul>
                                  {comment.replies.map((reply) => (
                                    <li key={reply.replyId}>
                                      * {reply.reply}
                                      <br />
                                    </li>
                                  ))}
                                </ul>
                              )}
                              <input
                                value={replyInputs[comment.commentId] || ""}
                                onChange={(event) =>
                                  handleReplyInputChange(
                                    comment.commentId,
                                    event
                                  )
                                }
                              />
                              <button
                                className="btn"
                                onClick={() =>
                                  handleReplySend(
                                    selectedBlog._id,
                                    comment.commentId
                                  )
                                }
                              >
                                Send
                              </button>
                            </div>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Popup for Adding New Blog */}
      {showNewBlogPopup && (
        <div className="popup">
          <div className="popup-inner">
            <h3>Add New Blog</h3>
            <button className="popup-close" onClick={closeNewBlogPopup}>
              X
            </button>
          </div>
          <div>
            <NewBlog onClose={closeNewBlogPopup} fetchBlogs={fetchBlogs} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Blog;
