import React, { useState, useEffect } from 'react';
import './Home.css'; // You can create a CSS file for styling

const Home = () => {
  const [contactsCount, setContactsCount] = useState(0);
  const [blogsCount, setBlogsCount] = useState(0);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        // Fetch contacts count
        const contactsResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/contacts`);
        if (!contactsResponse.ok) {
          throw new Error('Failed to fetch contacts');
        }
        const contacts = await contactsResponse.json();
        setContactsCount(contacts.length);

        // Fetch blogs count
        const blogsResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/blogs`);
        if (!blogsResponse.ok) {
          throw new Error('Failed to fetch blogs');
        }
        const blogs = await blogsResponse.json();
        setBlogsCount(blogs.length);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCounts();
  }, []);

  return (
    <div className="home-container">
      <h2>Welcome to Admin Dashboard</h2>
      <div className="counts-container">
        <div className="count-item">
          <h3>Total Blogs</h3>
          <p>{blogsCount}</p>
        </div>
        <div className="count-item">
          <h3>Total Contacts</h3>
          <p>{contactsCount}</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
