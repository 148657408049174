import React, { useState } from 'react';
import Blog from './Blog';
import Contact from './Contact';
import Home from './Home';
import { IoNotifications } from "react-icons/io5";


const Admin = () => {
  const [activeItem, setActiveItem] = useState('Home');

  const handleClick = (item) => {
    setActiveItem(item);
  };

  return (
    <div className=' mt-3'>
      <div className="card">
        <div className="card-body">
          <div className='card-nav'>
            <div className='left-nav'>
              <p onClick={() => handleClick('Home')} className={activeItem === 'Home' ? 'active' : ''}>Home</p>
              <p onClick={() => handleClick('Blog')} className={activeItem === 'Blog' ? 'active' : ''}>Blog</p>
              <p onClick={() => handleClick('Contact Us')} className={activeItem === 'Contact Us' ? 'active' : ''}>Contacts</p>
              {/* <IoNotifications style={{fontSize: 30}}/> */}
            </div>
          </div>

          <div className=''>
            <div className='right-nav'>
              {activeItem === 'Blog' && <Blog />}
              {activeItem === 'Contact Us' && <Contact />}
              {activeItem === 'Home' && <Home />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
