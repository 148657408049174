import React, { useState } from 'react';
import './Contact.css';
import { MdOutlineMarkEmailRead } from 'react-icons/md';
import { RiInstagramFill } from 'react-icons/ri';
import { RiWhatsappFill } from 'react-icons/ri';
import { IoIosCloudDone } from "react-icons/io";

const Contact = () => {
  const [messageSent, setMessageSent] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const sendEmail = async (event) => {
    event.preventDefault();

    const { name, email, message } = formData;

    try {
      const response = await fetch (`${process.env.REACT_APP_API_BASE_URL}/contacts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, message }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit form');
      }

      setMessageSent(true);

      // Clear form fields after 3 seconds
      setTimeout(() => {
        setMessageSent(false);
        setFormData({ name: '', email: '', message: '' });
      }, 3000);
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error state or display error message to user
    }
  };

  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact</h2>

      <div className="container contact__container">
        <div className="contact_options">
          <article className='contact__option'>
            <MdOutlineMarkEmailRead />
            <p>Email ID</p>
            <p> rishupandey80@gmail.com </p>
            <a href="mailto:rishupandey80@gmail.com" target='_blank' rel="noreferrer"> Send a message</a>
          </article>

          <article className='contact__option'>
            <RiInstagramFill />
            <p>Instagram</p>
            <p> Ritesh Pandey </p>
            <a href="https://www.instagram.com/_iampandey_/" target='_blank' rel="noreferrer"> Send a message</a>
          </article>

          <article className='contact__option'>
            <RiWhatsappFill />
            <p>Whatsapp</p>
            <p> +917054219942</p>
            <a href="https://api.whatsapp.com/send?phone=+917054219942" target='_blank' rel="noreferrer"> Send a message</a>
          </article>
        </div>

        <form onSubmit={sendEmail}>
          <input
            type="text"
            name="name"
            placeholder="Your Full Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <textarea
            name="message"
            rows="7"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
          <button type="submit" className="btn btn-primary w-100">
            Send Message
          </button>
        </form>

        {messageSent && (
          <div className="popup">
            <p><IoIosCloudDone />
              Thank you for your message!...
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

export default Contact;
