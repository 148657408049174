import React, { useState, useEffect, useRef } from "react";
import "./Nav.css";
import { AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { BiBook, BiMessageSquareDetail } from "react-icons/bi";
import { GoProject } from "react-icons/go";
import { IoMdPhotos } from "react-icons/io";
import { FaBlog } from "react-icons/fa";
import { RiLogoutCircleRFill, RiAdminFill } from "react-icons/ri";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";

const Nav = () => {
  const navigate = useNavigate();
  const [activeNav, setActiveNav] = useState("");
  const [isDropupVisible, setIsDropupVisible] = useState(false);
  const dropupRef = useRef(null);
  const loggedIn = localStorage.getItem("loggedIn");

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  const toggleDropup = () => {
    setIsDropupVisible(!isDropupVisible);
  };

  const handleClickOutside = (event) => {
    if (dropupRef.current && !dropupRef.current.contains(event.target)) {
      setIsDropupVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav>
      <Link
        to="/"
        onClick={() => setActiveNav("/")}
        className={activeNav === "/" ? "active" : ""}
        title="Home"
      >
        <AiOutlineHome />
      </Link>
      <Link
        to="/about"
        onClick={() => setActiveNav("/about")}
        className={activeNav === "/about" ? "active" : ""}
        title="About"
      >
        <AiOutlineUser />
      </Link>
      <Link
        to="/experience"
        onClick={() => setActiveNav("/experience")}
        className={activeNav === "/experience" ? "active" : ""}
        title="Experience"
      >
        <BiBook />
      </Link>
      <Link
        to="/project"
        onClick={() => setActiveNav("/project")}
        className={activeNav === "/project" ? "active" : ""}
        title="Project"
      >
        <GoProject />
      </Link>
      <Link
        to="/contactus"
        onClick={() => setActiveNav("/contactus")}
        className={activeNav === "/contactus" ? "active" : ""}
        title="Contact Us"
      >
        <BiMessageSquareDetail />
      </Link>
      <Link
        to="/blog"
        onClick={() => setActiveNav("/blog")}
        className={activeNav === "/blog" ? "active" : ""}
        title="Blogs"
      >
        <FaBlog />
      </Link>
      {loggedIn === "true" && (
        <>
          <div className="dropdown" ref={dropupRef}>
            <Link onClick={toggleDropup} title="More Options" className="dropdown-button">
              <HiOutlineDotsVertical />
            </Link>
            {isDropupVisible && (
              <div className="dropup-content">
                <Link
                  to="/admin"
                  onClick={() => {
                    setActiveNav("/admin");
                    setIsDropupVisible(false);
                  }}
                  className={activeNav === "/admin" ? "active" : ""}
                  title="Admin"
                >
                  <RiAdminFill />
                </Link>
                <Link
                  to="/"
                  onClick={() => {
                    handleLogout();
                    setIsDropupVisible(false);
                  }}
                  title="Logout"
                >
                  <RiLogoutCircleRFill />
                </Link>
              </div>
            )}
          </div>
        </>
      )}
    </nav>
  );
};

export default Nav;
