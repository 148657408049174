import React, { useState, useEffect } from "react";
import "./admin.css";

const Contact = () => {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        // Use environment variable for API base URL
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/contacts`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setContacts(data); // Assuming API returns an array of contacts
      } catch (error) {
        console.error("Error fetching contacts:", error);
        // Handle error fetching contacts
      }
    };

    fetchContacts();
  }, []);

  return (
    <div className="">
      <div className="contact-heading">
        <h2>Contact Forms</h2>
      </div>
      <div className="contacts">
        <ul style={{ padding: 0 }}>
          {contacts.map((contact) => (
            <div className="contact-li" key={contact._id}>
              <li>
                <strong>Name:</strong> {contact.name}
                <br />
                <strong>Email:</strong> {contact.email}
                <br />
                <strong>Message:</strong> {contact.message}
              </li>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Contact;
